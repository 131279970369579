/* Global */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
body {
  margin: 0;
  font-family: 'Varela Round', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.card {
  background-color: #ffffffeb !important;
}

button > svg {
  font-size: 1rem !important;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.page-item.active .page-link {
  font-weight: 600;
  color: #fff !important;
  background-color: #fff !important;
  border-color: #b7b6e7 !important;
  border: 1px solid transparent !important;
  min-width: 2.25rem;
}

.form-check-input:checked {
  background-color: #3c39a9 !important;
  border-color: #9fa7d3 !important;
}


/* Class for ORCA */
.bgImg1 {
  background-image: url("../../assets/images/orca-bg3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bgImg_gif {
  background-image: url("../../assets/images/orca-bg-gif.gif");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  -webkit-filter: brightness(.9);
  z-index: 1 !important;
}

.bgImg_gif2 {
  background-image: url("../../assets/images//orca-bg-gif2.gif");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  -webkit-filter: brightness(.9);
  z-index: 1 !important;
}

.text-orca {
  color: #2a2972 !important;
}

.text-orcaMid {
  color: rgb(67, 65, 167) !important;
}

.text-orcaDark {
  color: #1f1d71 !important;
}

.text-orcaDarker {
  color: #0f0e42 !important;
}

.text-orcaLight {
  color: #7170c2 !important;
}

.text-orcaLighter {
  color: #9fa7d3 !important;
}

.text-orcaWhite {
  color: #b5bad6 !important;
}

.text-orcaIcon {
  color: #2a2972 !important;
}

.text-orcaIcon:hover {
  color: #9fa7d3 !important;
}

.text-orcaLink {
  color: #3c39a9 !important;
  font-weight: 600;
}

.text-orcaLink:hover {
  color: #9fa7d3 !important;
  cursor: pointer;
}

.text-orcaLink2 {
  color: #6f6fb1 !important;
}

.text-orcaLink2:hover {
  color: #5150a1 !important;
  cursor: pointer;
}

.text-orcaIcon2 {
  color: #9fa7d3 !important;
}

.text-orcaIcon2:hover {
  color: #2a2972 !important;
}

.text-orcaIcon3 {
  color: #9fa7d3 !important;
}

.text-orcaIcon3:hover {
  color: #a19ff8 !important;
}

.text-point {
  color: #ec4d53 !important;
}

.text-red {
  color: #ad2e3b;
}

.bg-orca {
  background-color: #05042e !important;
}

.bg-orca2 {
  background-color: #070509 !important;
}


.bg-orcaMid {
  background-color: #3c39a9 !important;
}

.bg-orcaLight {
  background-color: #b7bddf !important;
}

.bg-orcaLighter {
  background-color: #ebedf9 !important;
}

.bg-orcaWhite {
  background-color: #ebedf963 !important;
}

.bg-transpWhite {
  background: #ffffffea !important;
}


.btn-orca {
  color: #fff !important;
  background-color: #4341a7 !important;
}

.btn-orca:hover {
  color: #05042e !important;
  background-color: #b7b6e7 !important;
}

.btn-orca2 {
  font-weight: 500 !important;
  color: #49486a !important;
  background-color: #b9b9eb !important;
}

.btn-orca2:hover {
  color: #fff !important;
  background-color: #7876b3 !important;
}

.btn-orca:disabled {
  border-color: #7876b3 !important;
  cursor: not-allowed !important;
}


.btn-orcaMid {
  color: #fff !important;
  background-color: #a3a9ca !important;
  border: 1px solid #a3a9ca !important;
}

.btn-orcaMid:hover {
  color: #05042e !important;
  background-color: #b7b6e7 !important;
  border: 1px solid #b7b6e7 !important;
}

.btn-orcaLight {
  color: #4341a7 !important;
  background-color: #e7ebff !important;
  border: 1px solid #e7ebff !important;
}

.btn-orcaLight:hover {
  color: #fff !important;
  background-color: #bec3e3 !important;
  border: 1px solid #bec3e3 !important;
}

.btn-orcaIcon {
  color: #4341a7 !important;
  background-color: transparent !important;
  border: none !important;
}

.btn-orcaIcon2 {
  color: #f7f7f7 !important;
  background-color: transparent !important;
  border: none !important;
  cursor: default !important;
}

.btn-orcaIcon3 {
  color: #f7f7f7 !important;
  background-color: transparent !important;
  border: none !important;
  cursor: pointer !important;
}


.btn-orcaDark {
  color: #8886fd !important;
  background-color: #05042e !important;
}

.btn-orcaDark:hover {
  color: #fff !important;
  background-color: #b7bddf !important;
}


.btn-IconLight {
  color: #fff !important;
  background-color: transparent !important;
  border: none !important;
}

.btn-IconLight:hover {
  color: rgba(255, 255, 255, 0.822) !important;
  background-color: transparent !important;
  border: none !important;
}

.btn-outline-orca {
  color: #4341a7 !important;
  border-color: #4341a7 !important;
  hover-color: #fff;
  hover-bg: #4341a7;
  hover-border-color: #4341a7;
  focus-shadow-rgb: 220,53,69;
  active-color: #fff;
  active-bg: #4341a7;
  active-border-color: #4341a7;
  active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
  disabled-color: #4341a7;
  disabled-bg: transparent;
  gradient: none;
  background-color: transparent !important;
}

.btn-outline-orca:focus, .btn-outline-orca:hover, .btn-outline-orca:active {
  color: #fff !important;
  background-color: #4341a7 !important;
  border-color: #4341a7 !important;
}

.btn-outline-orca2 {
  color: #8e8dc4 !important;
  border-color: #8e8dc4 !important;
  hover-color: #fff;
  hover-bg: #8e8dc4;
  hover-border-color: #8e8dc4;
  focus-shadow-rgb: 220,53,69;
  active-color: #fff;
  active-bg: #8e8dc4;
  active-border-color: #8e8dc4;
  active-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
  disabled-color: #8e8dc4;
  disabled-bg: transparent;
  gradient: none;
  background-color: transparent !important;
}

.btn-outline-orca2:hover, .btn-outline-orca2:active {
  color: #fff !important;
  background-color: #8e8dc4 !important;
  border-color: #8e8dc4 !important;
}

.btn-w-100 > button {
  width: 100%;
  display: inline-block !important;
}

.btn-save > button {
  /* position: absolute;
  right: 0%;
  top: -10%; */
  color: #fff !important;
  background-color: #50b68d !important;
  border-color: 1px solid #50b68d !important;
  z-index: 50;
}

.btn-save > button:hover {
  /* position: absolute;
  right: 0%;
  top: -10%; */
  z-index: 50;
  color: #fff !important;
  background-color: #409e79 !important;
  border-color: 1px solid #409e79 !important;
}

/* Forms */
.steper_default {
  width: 20px;
  height: 20px;
  border: 6px solid #b7bddf;
  box-shadow: 0px 0px 0px 5px #fff;
  background-color: #b7bddf;
  color: rgb(67, 65, 167);
}

.steper_active {
  width: 20px;
  height: 20px;
  border: 6px solid #4341a7;
  box-shadow: 0px 0px 0px 5px #fff;
  background-color: #4341a7;
  color: #fff;
}

.FormsMappingCard {
  display: flex !important;
  border: 1px solid rgb(203, 203, 203);
  border-radius: 0.5rem;
  padding: .75rem;
  margin-right: 0 !important;
  margin-left: 0 !important;
  width: 100%;
}

.FormsMappingCard > div {
  padding: 0rem .5rem;
}

.FormsMappingCard > div > .row {
  width: 100%;
}

.FormsMappingCard > div > h5 {
  text-align: start;
  color:#4341a7;
  font-weight: bolder;
}

.FormsMappingCard > div > .row > div:nth-child(1) {
  text-align: left;
  font-weight: bolder;
  color: #2a2972;
}

.FormsMappingCard > div > .row > div:nth-child(2) {
  text-align: right !important;
  color: #070509;
  padding-right: 0%;
}

/* Navbar */
.dropdown-toggle::after {
  color: #fff;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: #4341a7 !important;
  background-color: rgb(244 246 255) !important;
}

.noDropdownIcon::after {
  content: none !important;
}

.bellBadge {
  height: fit-content;
  align-self: flex-start;
  padding: 0.12rem 0.35rem !important;
  position: absolute;
  margin-left: 25px;
}

.NotiHover > .row:hover {
  background-color: #ebedf9 !important;
}

.uploading-icon {
  height: 1.5rem !important;
  width: 1.5rem !important;
  animation: 1.5s linear infinite spinner-border !important;
}

.passwordChangingSpinner {
  margin-top: .75rem;
}

/* Scrollbar style */
.scroller::-webkit-scrollbar {
  width: .4em;
}

.scroller::-webkit-scrollbar-button {
  background: transparent;
}

.scroller::-webkit-scrollbar-track-piece {
  background: transparent;
}

.scroller::-webkit-scrollbar-track {
  box-shadow: inset 0px transparent;
}

.scroller::-webkit-scrollbar-thumb {
  background-color: #ced4da;
  border-radius: 50rem;
}


/* Hidden scroller */
.ScrollbarHide::-webkit-scrollbar {
  width: 0em;
}

.ScrollbarHide::-webkit-scrollbar-button {
  background: transparent;
}

.ScrollbarHide::-webkit-scrollbar-track-piece {
  background: transparent;
}

.ScrollbarHide::-webkit-scrollbar-track {
  box-shadow: inset 0px transparent;
}

.ScrollbarHide::-webkit-scrollbar-thumb {
  background-color: #4341a7;
  border-radius: 50rem;
}

/* Homepage */
.HomepageCard::-webkit-scrollbar {
  width: .5em;
}

.HomepageCard::-webkit-scrollbar-button {
  background: transparent;
}

.HomepageCard::-webkit-scrollbar-track-piece {
  background: transparent;
}

.HomepageCard::-webkit-scrollbar-track {
  box-shadow: inset 0px transparent;
}

.HomepageCard::-webkit-scrollbar-thumb {
  background-color: #4341a7;
  border-radius: 50rem;
}


/* TableList */
.tableList > table {
  table-layout: fixed;
  border-top-left-radius: var(--bs-card-border-radius) !important;
  border-top-right-radius: var(--bs-card-border-radius) !important;
}

.tableList > table > thead > tr,
.tableList > table > thead > tr > th {
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  text-align: center;
  height: 2.5rem;
  color: #3d39a9;
  font-weight: bolder;
}

.tableList > table > thead > tr > th:nth-child(1) {
  width: 25%;
}

.tableList > table > thead > tr > th:nth-child(2) {
  width: 18%;
}

.tableList > table > thead > tr > th:nth-last-child(2) {
  width: 15%;
}

.tableList > table > tbody > tr {
  align-items: center;
}

.tableList > table > tbody > tr > td {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
  border-left-color: transparent;
  border-right-color: transparent;
  padding: .3rem 1rem;
}

.tableList > table > tbody > tr > td:nth-child(1) {
  padding: .3rem 0rem .3rem 2rem;
}

.tableList_log {
  min-height: 27rem;
  max-height: 27rem;
}

.tableList_log > table > tbody > tr > td, .tableList_log > table > thead > tr > th {
  background-color: #f3f3f3;
  height: 2.5rem;
}

.tableList_members {
  min-height: 32rem;
  max-height: 32rem;
}

.tableList_members > table > tbody > tr > td, .tableList_members > table > thead > tr > th {
  background-color: #fff;
  height: 2.5rem;
}

/* Pagination */
#TablePagination > div{
  flex-direction: column !important;
}

#TablePagination > div > .pagination {
  border: 1px solid #ced4da;
  border-radius: 5px;
}

#TablePagination > div > .pagination > li {
  flex-grow: 1;
}

#TablePagination > div > .pagination > li > a {
  display: flex;
}

#TablePagination > div > .pagination > li > a > span {
  margin-left: auto !important;
  margin-right: auto;
  color: transparent;
}

#TablePagination > div > .pagination > .active {
  background-color:#fff;
  align-self: center;
}

#TablePagination > div > .pagination > .active > span {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: .5rem !important;
  box-sizing: border-box;
  border: 5px solid;
}

#TablePagination > div > .pagination > .prev > a {
  background-image: url('../images/arrow-left.svg');
  background-repeat: no-repeat;
  background-size: 3%;
  background-position-y: center;
  background-position-x: 3%;
  color: #2a2972;
  border: 1px solid #fff;
}

#TablePagination > div > .pagination > .next > a {
  background-image: url('../images/arrow-right.svg');
  background-repeat: no-repeat;
  background-size: 3%;
  background-position-y: center;
  background-position-x: 97%;
  color: #2a2972;
  border: 1px solid #fff;
}

/* Dialog */
.ContactUs {
  background-color: #e7ebff;
  width: 100%;
}

.ContactUs > a {
  text-decoration: none;
  color: #b7b6e7;
  font-weight: 500;
  font-size: .9rem;
  margin: 0 auto;
}

.ContactUs > a:hover {
  color: #4341a7;
}

.listSelector {
  border: 1px solid #ced4da;
  border-radius: .25rem;
  display: block !important;
}

.listSelector > .list-group {
  padding: 0rem;
  height: 41px;
}

.listSelector > .list-group:nth-child(1) > div {
  border-top: none;
}

.listSelector > .list-group > div {
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  height: 100%;
  display: flex !important;
}

.listSelector > .list-group > div:hover {
  background-color: rgb(244 246 255);
}

.listSelector::-webkit-scrollbar {
  width: .4em;
}

.listSelector::-webkit-scrollbar-button {
  background: transparent;
}

.listSelector::-webkit-scrollbar-track-piece {
  background: transparent;
}

.listSelector::-webkit-scrollbar-track {
  box-shadow: inset 0px transparent;
}

.listSelector::-webkit-scrollbar-thumb {
  background-color: #bec3e3;
  border-radius: 50rem;
}

/* Analytics */
.AnaOverflow::-webkit-scrollbar {
  width: .5em;
}

.AnaOverflow::-webkit-scrollbar-button {
  background: transparent;
}

.AnaOverflow::-webkit-scrollbar-track-piece {
  background: transparent;
}

.AnaOverflow::-webkit-scrollbar-track {
  box-shadow: inset 0px transparent;
}

.AnaOverflow::-webkit-scrollbar-thumb {
  background-color: #4341a7;
  border-radius: 50rem;
}

/* React Select */
/* [id*="-option-"] {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
} */

#transp_select,
#transp_select > span,
#transp_select > div,
#transp_select > div > div > span {
  background-color: transparent !important;
  border: 0px solid transparent !important;
  user-select: none !important;
}

#transp_select > div > div > div {
  background-color: transparent !important;
  border: 0px solid transparent !important;
  padding: 0 !important;
  color: #2a2972 !important;
}

#transp_select > div > div > div > svg {
  display: none !important;
}

/* Tooltip */
.Tips > .tooltip-inner {
  max-width: 290px;
  background-color: rgba(0, 0, 0, 0.815);
  box-shadow: 0px 0px 2px 0px #64646485;
  border-color: transparent;
  text-align: left;
}

.Tips-rule > .tooltip-inner {
  min-width: 13rem !important;
  background-color: rgba(0, 0, 0, 0.827);
  box-shadow: 0px 0px 2px 0px #64646485;
  border-color: transparent;
}

.Tips-rule > .tooltip-inner > .row {
  display: flex !important;
}

.Tips-mailCampaign > .tooltip-inner {
  max-width: 500px !important;
  background-color: rgba(0, 0, 0, 0.827);
  box-shadow: 0px 0px 2px 0px #64646485;
  border-color: transparent;
  height: fit-content;
  flex-wrap: nowrap !important;
}

.Tips-mailCampaign > .tooltip-inner > .row {
  display: flex !important;
}

/* Date Time Picker */
.picky-date-time {
  box-shadow: none !important;
  padding-top: 10px !important;
  padding-bottom: 5px !important;
}

.picky-date-time-clock__inline-div--middle > svg {
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin-left: .5rem;
}

.picky-date-time-clock__inline-div--middle > svg:hover {
  cursor: pointer !important;
}

.picky-date-time-calendar__button {
  display: flex !important;
  color: #3c39a9 !important;
  background-color: #d0d4ed !important;
  border: 1px solid #d0d4ed !important;
  border-radius: .25rem !important;
  font-size: .95rem !important;
  padding: .5rem !important;
  margin-top: 0 !important;
}

.picky-date-time-calendar__button:hover {
  background: #b7b6e7 !important;
  border: 1px solid #b7b6e7 !important;
}

.picky-date-time-calendar__button > span {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.picky-date-time-calendar__button > svg {
  color: #3c39a9 !important;
  margin-left: .3rem;
  width: 1.2rem !important;
  height: 1.2rem !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.picky-date-time-calendar__table-cel.active:not(.today),
.picky-date-time-calendar__table-cel.picky-date-time-calendar__date-item:not(.disabled):hover {
  background: #b7b6e7 !important;
  border: 1px solid #b7b6e7 !important;
}

.picky-date-time-clock__clock-hand--second {
  background: #7170c2 !important;
}

.picky-date-time-calendar__title:hover {
  color: #7170c2 !important;
}

.picky-date-time-clock__circle {
  margin: 18px auto 42px !important;
}

.picky-date-time-clock__inputer {
  border-radius: .25rem !important;
  padding: .45rem !important;
}

.picky-date-time-calendar__body-container {
  height: 255px !important;
}

.picky-date-time__calendar {
  width: auto !important;
}

.picky-date-time__close {
  display: none !important;
}

/* Date Time React-Calender */
.react-calendar {
  border: none !important;
  line-height: 1.75rem !important;
  font-family: 'Varela Round', sans-serif !important;
}

.react-calendar__tile:enabled:hover {
  background-color: transparent !important;
  color: #4341a7 !important;
}

.react-calendar__tile--active,
.react-calendar__tile:enabled:focus,
.react-calendar__tile--hasActive {
  background: #3d39a9 !important;
  color: #fff !important;
  border-radius: .25rem;
}

.react-calendar__tile--now {
  background: #e7ebff !important;
  color: #4341a7 !important;
  border-radius: .25rem;
}

.react-calendar__month-view__days__day--weekend:enabled:hover {
  color: #e83f3f !important;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 1.22rem 0.5rem !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e7ebff !important;
  border-radius: .25rem;
}


/* Tools class */
.unselectable {
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  -webkit-touch-callout: none !important;
}

.noLinkDeco {
  text-decoration: none !important;
}

.oneLineLimit {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
}

.twoLineLimit {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.noDeco {
text-decoration: none;
}

.vh-100 {
  height: 100vh !important;
}

.vh-95 {
  height: 95vh !important;
}

.h-15 {
  height: 15% !important;
}

.h-10 {
  height: 10% !important;
}

.h-9 {
  height: 9% !important;
}

.h-8 {
  height: 8% !important;
}

.h-7 {
  height: 7% !important;
}

.h-5 {
  height: 5% !important;
}

.h-3 {
  height: 3% !important;
}

.h-95 {
  height: 95% !important;
}

.h-92 {
  height: 92% !important;
}

.h-90 {
  height: 90% !important;
}

.h-87 {
  height: 87% !important;
}

.h-85 {
  height: 85% !important;
}

.h-80 {
  height: 80% !important;
}

.h-78 {
  height: 78% !important;
}

.h-70 {
  height: 70% !important;
}

.h-65 {
  height: 65% !important;
}

.h-60 {
  height: 60% !important;
}

.vh-5 {
  height: 5vh !important;
}

.w-95 {
  width: 95% !important;
}

.w-90 {
  width: 90% !important;
}

.w-85 {
  width: 85% !important;
}

.w-80 {
  width: 80% !important;
}

.w-70 {
  width: 70% !important;
}

.w-60 {
  width: 60% !important;
}

.w-55 {
  width: 55% !important;
}

.w-30 {
  width: 30% !important;
}

.w-20 {
  width: 20% !important;
}

.w-15 {
  width: 15% !important;
}


/* Rotate animation */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .load-icon {
    animation: load-icon-spin infinite 15s linear;
  }

  .logo-hover-rotate:hover {
    animation: load-icon-spin infinite 18s linear;
    cursor: crosshair;
  }
}

@keyframes load-icon-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .load-icon {
    animation: load-icon-spin infinite 2s linear;
  }
}
